<template>
  <Modal :back="false">
    <div class="flex-full">
      <a href="#" class="menu-item" @click.prevent="navigate('info')">
        Informatie
      </a>

      <a href="#" class="menu-item" @click.prevent="navigate('download')">
        Offline beschikbaar maken
      </a>

      <a href="#" class="menu-item" @click.prevent="state.reset">
        Opnieuw beginnen
      </a>

      <a href="https://vezwolle.nl/" target="blank" class="menu-item">Website VEZ</a>

      <a :href="$options.bugReportUrl" class="menu-item">
        Foutje gezien?
      </a>
    </div>
  </Modal>
</template>

<script>
import Modal from '../common/Modal'
import state from '../../state'

let bugReportUrl = 'mailto:jpkleemans@gmail.com'
bugReportUrl += '?subject=' + encodeURIComponent('Foutje in de VEZ Pinksterwandeling app')
bugReportUrl += '&body=' + encodeURIComponent('Hoi! Ik heb een foutje gevonden in de VEZ Pinksterwandeling app. Namelijk:\n\n')

export default {
  bugReportUrl,
  components: {
    Modal
  },
  emits: ['close'],
  setup () {
    return { state }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    navigate (to) {
      this.$router.push({ name: to })
      this.close()
    }
  }
}
</script>

<style scoped>
.menu-item {
  @apply mb-6 text-xl;
}
</style>
